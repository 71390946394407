<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date" label="报表日期" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date" @input="menu = false" :first-day-of-week="1" locale="zh-cn"
                        color="primary" hide-details></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="6" order-md="3" sm="12">
                <v-card>
                    <v-card-title class="align-start"><span>访问统计</span>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <vue-apex-charts id="chart-sales-overview" :options="chartOptions" :series="chartData"
                                    class="h-full d-flex align-center"></vue-apex-charts>
                            </v-col>
                            <v-col class="d-flex flex-column justify-center" cols="12" sm="6">
                                <div class="d-flex align-center">
                                    <v-avatar class="v-avatar-light-bg primary--text" rounded size="40">
                                        <v-icon color="primary" size="30"> {{ icons.mdiKeyboardSettings }}</v-icon>
                                    </v-avatar>
                                    <div class="ms-4 d-flex flex-column">
                                        <p class="text--primary mb-0 text-base"> 缓存配置 </p> <span
                                            class="text--primary font-weight-semibold text-xl">配置:{{ data.total.cache_cnt
                                            }}&nbsp;&nbsp;TOKEN:{{ data.total.cache_token_cnt }}</span>
                                    </div>
                                </div>
                                <v-divider class="my-6"></v-divider>
                                <table class="sales-overview-stats-table">
                                    <tr>
                                        <td>
                                            <div class="mb-0">
                                                <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                                                <span>总访问</span>
                                            </div>
                                            <span class="text-base text--primary font-weight-semibold ms-4">{{
                                                data.total.all_total }}</span>
                                        </td>
                                        <td>
                                            <div class="mb-0">
                                                <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                                                <span>直接访问</span>
                                            </div>
                                            <span class="text-base text--primary font-weight-semibold ms-4">{{
                                                data.total.cache_direct_total }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div class="mb-0">
                                                <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                                                <span>内存缓存</span>
                                            </div>
                                            <span class="text-base text--primary font-weight-semibold ms-4">{{
                                                data.total.cache_mem_total }}</span>
                                        </td>
                                        <td>
                                            <div class="mb-0">
                                                <div class="stats-dot secondary d-inline-block rounded-circle me-2"></div>
                                                <span>磁盘缓存</span>
                                            </div>
                                            <span class="text-base text--primary font-weight-semibold ms-4">{{
                                                data.total.cache_disk_total }}</span>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6" order-md="3" sm="12">
                <v-card>
                    <v-card-title><span class="text-sm font-weight-semibold">命中趋势</span>
                        <v-spacer></v-spacer>
                        <v-chip class="v-chip-light-bg secondary--text font-weight-semibold" small> {{
                            `${chartDataWeekRange.start} ~ ${chartDataWeekRange.end}` }}</v-chip>
                    </v-card-title>
                    <!-- Chart -->
                    <vue-apex-charts :options="chartOptionsWeek" :series="chartDataWeek" height="240px"></vue-apex-charts>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <template v-for="(item, i) in data.cache_list">
                <v-col cols="12" md="4" order-md="3" sm="12">
                    <v-card>
                        <v-card-title class="align-start"><span class="font-weight-semibold">总访问次数</span>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-subtitle class="mb-7"><span class="font-weight-semibold text--primary me-1">缓存+直接访问</span>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-col class="d-flex align-center" cols="12" sm="12">
                                    <v-avatar color="primary" class="elevation-1" rounded size="44">
                                        <v-icon color="white" dark size="30"> {{ icons.mdiSelectAll }}</v-icon>
                                    </v-avatar>
                                    <div class="ms-3">
                                        <p class="text-xs mb-0"> 总访问</p>
                                        <h3 class="text-xl font-weight-bold"> {{ item.all_total }} </h3>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="8" order-md="3" sm="12">
                    <v-card>
                        <v-card-title class="align-start">
                            <span class="font-weight-semibold">{{ item.name }}</span>
                            <span class="text-sm">{{ date }}</span>
                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-subtitle class="mb-7"><span class="font-weight-semibold text--primary me-1">{{ item.template
                        }}</span>
                        </v-card-subtitle>
                        <v-card-text>
                            <v-row>
                                <v-col class="d-flex align-center" cols="6" sm="4">
                                    <v-avatar color="success" class="elevation-1" rounded size="44">
                                        <v-icon color="white" dark size="30"> {{ icons.mdiBikeFast }}</v-icon>
                                    </v-avatar>
                                    <div class="ms-3">
                                        <p class="text-xs mb-0"> 内存缓存</p>
                                        <h3 class="text-xl font-weight-bold"> {{ item.cache_mem_total }} </h3>
                                    </div>
                                </v-col>
                                <v-col class="d-flex align-center" cols="6" sm="4">
                                    <v-avatar color="info" class="elevation-1" rounded size="44">
                                        <v-icon color="white" dark size="30"> {{ icons.mdiRunFast }}</v-icon>
                                    </v-avatar>
                                    <div class="ms-3">
                                        <p class="text-xs mb-0"> 磁盘缓存</p>
                                        <h3 class="text-xl font-weight-bold"> {{ item.cache_disk_total }} </h3>
                                    </div>
                                </v-col>
                                <v-col class="d-flex align-center" cols="6" sm="4">
                                    <v-avatar color="warning" class="elevation-1" rounded size="44">
                                        <v-icon color="white" dark size="30"> {{ icons.mdiCoffee }}</v-icon>
                                    </v-avatar>
                                    <div class="ms-3">
                                        <p class="text-xs mb-0"> 直接访问</p>
                                        <h3 class="text-xl font-weight-bold"> {{ item.cache_direct_total }} </h3>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
        </v-row>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
// icons
import { mdiLabelVariantOutline } from '@mdi/js'
// demos
import axios from '@axios'
import { mdiAccountOutline, mdiDotsVertical, mdiLabelOutline, mdiKeyboardSettings, mdiBikeFast, mdiRunFast, mdiCoffee, mdiSelectAll } from '@mdi/js'
import { ref, watch, onMounted, onUnmounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { addAlpha, getVuetify } from '@core/utils'

export default {
    components: {
        VueApexCharts: () => import('vue-apexcharts')
    }, setup() {
        // card with images
        const ratingsOptions = { statTitle: 'Ratings', statistics: '13k', change: '+38%', chipText: 'Year of 2021', chipColor: 'primary', avatar: require('@/assets/images/avatars/9.png'), avatarWidth: '111', }
        const sessionsOptions = { statTitle: 'Sessions', statistics: '24.5k', change: '-22%', chipText: 'Last Week', chipColor: 'secondary', avatar: require('@/assets/images/avatars/10.png'), avatarWidth: '86', }
        const growthAreaChart = { statTitle: 'Total Growth', statistics: '42.5k', series: [{ name: 'Subscribers', data: [28, 40, 36, 52, 38, 60, 55], },], }
        // vertical card options
        const newProjectOptions = { statTitle: 'New Project', icon: mdiLabelVariantOutline, color: 'primary', subtitle: 'Yearly Project', statistics: '862', change: '-18%', }

        const enums = {
            // // 1=订单完成 11=用户取消 12=超时自动取消 96=送达乘客 97=接到乘客 98=司机接单 99=提交订单（司机看不到99状态）
            status: { 1: "已完成", 11: "用户取消", 12: "超时自动取消", 96: "已送达", 97: "接到乘客", 98: "已接单", 99: "待接单" },
            statusColor: { 1: "success", 11: "error", 12: "error", 96: "primary", 97: "secondary", 98: "info", 99: "warning" },
        }

        const $vuetify = getVuetify()
        const chartOptions = {
            labels: ['内存命中', '磁盘命中', '直接访问'],
            colors: [addAlpha($vuetify.theme.currentTheme.primary, 0.7), addAlpha($vuetify.theme.currentTheme.primary, 0.3), addAlpha($vuetify.theme.currentTheme.secondary, 0.1),],
            chart: { id: "total_chart", type: 'donut', sparkline: { enabled: true, }, },
            plotOptions: {
                pie: {
                    donut: {
                        size: '70%', labels: {
                            show: true, name: { fontSize: '12px', offsetY: 25, }, value: {
                                fontSize: '1.625rem', fontWeight: 600, offsetY: -15, formatter(value) {
                                    return `${value}`
                                },
                            }, total: {
                                show: true, label: '总访问', color: 'rgba(94, 86, 105, 0.68)', formatter(value) {
                                    return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                                },
                            },
                        },
                    },
                },
            },
        }
        const chartData = ref([96, 32, 8])

        const chartOptionsWeek = ref({
            chart: { stacked: true, type: 'bar', toolbar: { show: false }, },
            grid: { yaxis: { lines: { show: true }, }, },
            xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'], labels: { show: false, }, axisTicks: { show: false, }, axisBorder: { show: false, }, },
            legend: { markers: { radius: 15, }, },
            stroke: { width: 5, colors: ['#fff'], },
            dataLabels: { enabled: false, },
            colors: [$vuetify.theme.currentTheme.success, $vuetify.theme.currentTheme.secondary],
            plotOptions: { bar: { columnWidth: '70%', borderRadius: 8, startingShape: 'rounded', endingShape: 'rounded', }, },
            yaxis: { labels: { show: false, }, },
            responsive: [{ breakpoint: 3000, options: { plotOptions: { bar: { columnWidth: '50%', }, }, }, }, { breakpoint: 1580, options: { stroke: { width: 3, }, }, }, { breakpoint: 1300, options: { stroke: { width: 1, }, }, }, {
                breakpoint: 958,
                options: { stroke: { width: 5, }, },
            }, { breakpoint: 750, options: { stroke: { width: 4, }, }, }, { breakpoint: 600, options: { stroke: { width: 3, }, }, },],
        })
        const chartDataWeek = ref([{ name: '缓存命中', data: [95, 177, 284, 256, 105, 63, 168, 218, 72], }, { name: '直接访问', data: [-145, -80, -60, -180, -100, -60, -85, -75, -100], },])
        const chartDataWeekRange = ref({ start: '', end: '' })

        const date = ref('')
        const menu = ref(false)
        const loading = ref('false')
        const data = ref({
            "total": { "cache_cnt": 1, "cache_token_cnt": 1, "all_total": 219, "cache_mem_total": 171, "cache_disk_total": 36, "cache_direct_total": 12 },
            "latest": [
                { "day": "2023-09-25", "cache_total": 0, "direct_total": 1 },
                { "day": "2023-09-26", "cache_total": 0, "direct_total": 11 }
            ],
            "cache_list": [
                { "name": "中科星图", "code": "zkxt", "template": "https://tiles1.geovisearth.com/base/v1/img/{z}/{x}/{y}?format={format}\u0026tmsIds=w\u0026token={token}", "all_total": 145, "cache_mem_total": 120, "cache_disk_total": 14, "cache_direct_total": 11 }
            ]
        })

        const fetchReportAll = () => {
            loading.value = true
            axios.get("/cache/v1/tile-cache/statistics", { params: { date: date.value } }).then(res => {
                const { code, msg } = res.data
                if (code != 200) {
                    return
                }
                data.value = res.data.data
                const { total, latest } = res.data.data
                // 统计
                chartData.value = [total.cache_mem_total, total.cache_disk_total, total.cache_direct_total,]
                // 趋势
                const sr = []
                const week = [{ name: '缓存命中', data: [], }, { name: '直接访问', data: [] }]
                for (let i = 0; i < (latest || []).length; i++) {
                    const item = latest[i]
                    sr.push(item.day)
                    if (i == 0) {
                        chartDataWeekRange.value.start = item.day
                    }
                    if (i == latest.length - 1)
                        chartDataWeekRange.value.end = item.day
                    week[0].data.push(item.cache_total)
                    week[1].data.push(-item.direct_total)
                }
                chartDataWeek.value = week
                chartOptionsWeek.value = {
                    ...chartOptionsWeek.value,
                    xaxis: { categories: sr, labels: { show: false, }, axisTicks: { show: false, }, axisBorder: { show: false, }, }
                }
            }).catch(error => {
            }).finally(loading.value = false)
        }

        const getCurrentTime = () => {
            var date = new Date();//当前时间
            var month = zeroFill(date.getMonth() + 1);//月
            var day = zeroFill(date.getDate());//日
            var curTime = date.getFullYear() + "-" + month + "-" + day
            return curTime;
        }

        function zeroFill(i) {
            return i >= 0 && i <= 9 ? "0" + i : i
        }

        const { router } = useRouter()
        const toPage = (path) => {
            router.push(path)
        }

        let timer = null

        date.value = getCurrentTime()
        fetchReportAll()

        onMounted(() => {
            timer = setInterval(() => {
                fetchReportAll()
            }, 3000)
        })

        onUnmounted(() => {
            clearInterval(timer)
            timer = null
        })

        watch([date], () => {
            fetchReportAll()
        }, { deep: true })

        return {
            chartOptions, chartData,
            chartOptionsWeek, chartDataWeek, chartDataWeekRange,
            date, menu, data, enums, loading, ratingsOptions, sessionsOptions, growthAreaChart, newProjectOptions, toPage,
            icons: { mdiKeyboardSettings, mdiAccountOutline, mdiDotsVertical, mdiLabelOutline, mdiBikeFast, mdiRunFast, mdiCoffee, mdiSelectAll },
        }

    },
} </script>

<style lang="scss"> #chart-sales-overview {
     .apexcharts-canvas {
         .apexcharts-text {
             &.apexcharts-datalabel-value {
                 font-weight: 600;
             }

             &.apexcharts-datalabel-label {
                 font-size: 1rem;
             }
         }
     }
 }

 .sales-overview-stats-table {
     width: 100%;

     td {
         padding-bottom: 1rem;
     }

     .stats-dot {
         padding: 0.33rem;
     }

     // Set opacity of dots
     tr {
         &:nth-of-type(1) {
             td:nth-of-type(2) {
                 .stats-dot {
                     opacity: 0.7;
                 }
             }
         }

         &:nth-of-type(2) {
             td:nth-of-type(1) {
                 .stats-dot {
                     opacity: 0.5;
                 }
             }

             td:nth-of-type(2) {
                 .stats-dot {
                     opacity: 0.15;
                 }
             }
         }
     }
 }
</style>